import store from '../store';

import CouponsService from '../services/CouponsService';

export default {
  async fetchBetweenDates({
    startDate, endDate, onSuccess, onError,
  }) {
    try {
      const sessions = await CouponsService.fetchBetweenDates({ startDate, endDate });

      store.dispatch({
        type: 'FETCH',
        sessions,
      });

      onSuccess?.({ msg: 'Sessions succesfully fetched.' });
    } catch (error) {
      onError?.({ ...error, msg: 'Error while trying to fetch the sessions.' });
    }
  },
};
