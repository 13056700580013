import CustomError from '../custom/CustomError';

import store from '../store';

export default {
  async request({ url, method = 'GET', body }) {
    const { env, key, serverURLs } = store.getState().globalConfig;

    const headers = {
      'Content-Type': 'application/json',
      'API-Key': key,
    };

    try {
      const response = await fetch(`${serverURLs[env]}${url}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
      });

      if (response.status >= 400) {
        try {
          const data = await response.text();
          throw new CustomError({ status: response.status, env, backendMsg: data?.msg });
        } catch (error) {
          if (error.name !== 'CustomError') throw new CustomError({ status: response.status, env });
          else throw error;
        }
      }

      const data = await response.text();
      return data;
    } catch (error) {
      if (error.name !== 'CustomError') throw new CustomError({ couldReachServer: false, env });
      else throw error;
    }
  },
};
