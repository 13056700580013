import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import sha256 from 'crypto-js/sha256';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import GlobalConfigActions from '../../actions/GlobalConfigActions';

const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.up('md')]: {
      minWidth: 500,
    },
  },
}));

function isValid(key) {
  const keys = [
    '901cf7',
    '759ac2',
    '6fad52',
    '7a07cc',
    'b04c87c',
    '002a517',
    '724db48',
    '948dec9',
    '374a4c4',
    'df28f52',
    '776acec',
    'dc76568',
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const k of keys) {
    if (key.indexOf(k) === 0) return true;
  }

  return false;
}

const SetApiKeysFormDialog = ({
  open, onClose, onSave,
}) => {
  const classes = useStyles();

  const env = useSelector((state) => state.globalConfig.env);

  const storeKeys = useSelector((state) => state.globalConfig.keys); // keys from store (read only)
  const [keys, setKeys] = useState({ ...storeKeys }); // private local copy of keys from store

  const [login, setLogin] = useState(''); // private local copy of keys from store
  const [password, setPassword] = useState(''); // private local copy of keys from store

  const initialKeys = useRef({ ...storeKeys }); // copy of initial keys from store

  useEffect(() => {
    // when the keys in store change, we update our local copy
    setKeys({ ...storeKeys });
  }, [storeKeys]);

  useEffect(() => {
    // when the dialog becomes visible, we save the initial keys from store
    if (open) {
      initialKeys.current = { ...storeKeys };
    }
  }, [open]);

  const handleOnClose = () => {
    setKeys({ ...storeKeys });

    onClose();
  };

  const handleOnSaveClick = () => {
    const key = sha256(`${login}`.toLowerCase().trim() + `${password}`.trim()).toString();

    // nicolas@seety.co & test
    if (!isValid(key)) {
      console.log(key);
      return alert('Login / Mot de passe invalide');
    }

    GlobalConfigActions.changeKey({ key });

    return onSave();
  };

  const handleOnChange = (e, field) => {
    if (field === 'login') return setLogin(e.target.value);
    if (field === 'password') return setPassword(e.target.value);
    return null;
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>Connexion</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">

          <TextField
            label="Login"
            value={login}
            margin="normal"
            className={classes.textField}
            onChange={(e) => { handleOnChange(e, 'login'); }}
          />

          <TextField
            label="Password"
            value={password}
            margin="normal"
            type="password"
            className={classes.textField}
            onChange={(e) => { handleOnChange(e, 'password'); }}
          />
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnSaveClick} color="primary">
          Se connecter
        </Button>
      </DialogActions>
    </Dialog>

  );
};

SetApiKeysFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SetApiKeysFormDialog;
