import dayjs from '../custom/customDayjs';

export const getCampaignsTableColumns = () => [
  {
    name: 'city',
    label: 'Ville',
    options: {
      filter: true,
      display: true,
    },
  },
  {
    name: 'zone id',
    label: 'Zone',
    options: {
      filter: true,
    },
  },

  {
    name: 'address',
    label: 'Adresse',
    options: {
      filter: false,
      display: true,
    },
  },

  {
    name: 'parking price',
    label: 'Prix €',
    options: {
      filter: true,
    },
  },

  {
    name: 'startDate',
    label: 'Début',
    options: {
      filter: false,
      customBodyRender: (value) => dayjs(value).format('LLL'),
      sortDirection: 'desc',
    },
  },

  {
    name: 'endDate',
    label: 'Fin',
    options: {
      filter: false,
      customBodyRender: (value) => (value ? dayjs(value).format('LLL') : 'En cours'),
    },
  },

  {
    name: 'duration',
    label: 'Durée',
    options: {
      filter: false,
      display: true,
    },
  },

  {
    name: 'plate',
    label: 'Plaque',
    options: {
      filter: true,
    },
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      display: true,
    },
  },

  {
    name: 'parking booking',
    label: 'ID',
    options: {
      sort: false,
      filter: false,
      display: true,
    },
  },
];

export const dummy = () => {};
