import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TopAppBar from './components/TopAppBar';
import CouponCampaigns from './components/CouponCampaigns';
import SnackbarsManager from './components/SnackbarsManager';

const CouponsControlPanel = () => {
  const theme = useTheme();
  const mdOrUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box bgcolor="background.default" minHeight="100vh">

      {/* container for the top AppBar (full width) */}
      <Container disableGutters maxWidth={false}>
        <TopAppBar />
      </Container>

      {/* container for the app proper (one-page app) */}
      <Box p={mdOrUp ? 6 : 2}>
        <Container maxWidth="xl" disableGutters={!mdOrUp}>
          <CouponCampaigns />
          <SnackbarsManager />
        </Container>
      </Box>

    </Box>
  );
};

export default CouponsControlPanel;
