export default class CustomError extends Error {
  constructor({
    msg = '', status, couldReachServer = true, env, backendMsg,
  }) {
    super(msg);

    this.name = 'CustomError';
    this.status = status;
    this.couldReachServer = couldReachServer;
    this.env = env;
    this.backendMsg = backendMsg;
  }
}
