import React from 'react';

import { useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

import CouponCampaignsTable from './CouponCampaignsTable';
import AbsentKeyAlert from './AbsentKeyAlert';

const getEnvColorForBoxBorder = ({ env }) => {
  switch (env) {
    case 'local': return 'success.main';
    case 'preprod': return 'error.main';
    case 'prod': return 'grey.600';
    default: return '';
  }
};

const CouponCampaigns = () => {
  const theme = useTheme();
  const mdOrUp = useMediaQuery(theme.breakpoints.up('md'));

  const env = useSelector((state) => state.globalConfig.env);
  const keyForCurrentEnv = useSelector((state) => state.globalConfig.key);

  return (
    <Box>
      <Box
        px={mdOrUp ? 4 : 2}
        py={1}
        borderLeft={2}
        borderColor={getEnvColorForBoxBorder({ env })}
      >

        {keyForCurrentEnv ? (
          <CouponCampaignsTable />
        ) : (
          <AbsentKeyAlert env={env} />
        )}
      </Box>
    </Box>
  );
};

export default CouponCampaigns;
