import { combineReducers } from 'redux';

import globalConfig from './globalConfig';
import sessions from './coupons';
import snackbars from './snackbars';

export default combineReducers({
  globalConfig,
  sessions,
  snackbars,
});
