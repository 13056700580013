import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

import SetApiKeysFormDialog from '../GlobalConfig/SetApiKeysFormDialog';

const AbsentKeyAlert = ({ env }) => {
  const [mustShowFormDialog, setMustShowFormDialog] = useState(false);

  const handleOnSetKeyButtonClick = () => {
    setMustShowFormDialog(true);
  };

  const handleOnFormDialogClose = () => {
    setMustShowFormDialog(false);
  };

  const handleOnFormDialogSave = () => {
    setMustShowFormDialog(false);
  };

  return (
    <Box>
      <Alert
        severity="warning"
      >
        <AlertTitle>Absent key</AlertTitle>
        <Typography variant="body2" gutterBottom>
          Avant de pouvoir utiliser le backoffice, merci de vous identifier:
        </Typography>

        <Button color="primary" onClick={handleOnSetKeyButtonClick}>
          Connexion
        </Button>
      </Alert>

      <SetApiKeysFormDialog
        open={mustShowFormDialog}
        onClose={handleOnFormDialogClose}
        onSave={handleOnFormDialogSave}
        autoFocus={env}
      />
    </Box>
  );
};

AbsentKeyAlert.propTypes = {
  env: PropTypes.string.isRequired,
};

export default AbsentKeyAlert;
