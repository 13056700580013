import produce from 'immer';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const env = urlParams.get('env') || 'prod';

export default function globalConfig(state = {
  env,
  serverURLs: {
    local: 'http://localhost:4700/',
    preprod: 'https://api.beta.cparkapp.com/',
    prod: 'https://api.cparkapp.com/',
  },
  key: localStorage.getItem(`${env}Key`) || '',
}, action) {
  switch (action.type) {
    case 'CHANGE_KEY': {
      localStorage.setItem(`${env}Key`, action.key || '');

      return produce(state, (draft) => {
        draft.key = localStorage.getItem(`${env}Key`);
      });
    }

    default:
      return state;
  }
}
