import store from '../store';

export default {
  changeKey({ key }) {
    store.dispatch({
      type: 'CHANGE_KEY',
      key,
    });
  },
};
