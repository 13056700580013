import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

import CustomPropTypes from '../../custom/CustomPropTypes';

import SetApiKeysFormDialog from '../GlobalConfig/SetApiKeysFormDialog';

const getAlertTitle = ({ status, couldReachServer }) => {
  if (!couldReachServer) return 'Server unreachable';
  if (status === 401) return 'Unauthorized';
  return 'Request failed';
};

const getAlertMsg = ({ status, couldReachServer, env }) => {
  if (!couldReachServer) return `Couldn't reach the ${env} server. Check your internet connection or check the ${env} server status.`;
  if (status === 401) return `The ${env} key you've set seems invalid.`;
  return `The request failed with status code ${status}.`;
};

const RequestErrorAlert = ({
  error: {
    status, msg, couldReachServer, env,
  },
  onRetry,
}) => {
  const [mustShowSetKeysFormDialog, setMustShowSetKeysFormDialog] = useState(false);

  const handleOnSetKeyButtonClick = () => {
    setMustShowSetKeysFormDialog(true);
  };

  const handleOnSetKeysFormDialogClose = () => {
    setMustShowSetKeysFormDialog(false);
  };

  const handleOnSetKeysFormDialogSave = () => {
    setMustShowSetKeysFormDialog(false);
  };

  const renderButton = () => {
    if (status === 401) {
      return (
        <Button color="inherit" onClick={handleOnSetKeyButtonClick}>
          {`Set ${env} key`}
        </Button>
      );
    }
    return (
      <Button color="inherit" onClick={onRetry}>
        Retry
      </Button>
    );
  };

  return (
    <Box>
      <Alert
        severity="error"
      >
        <AlertTitle>{getAlertTitle({ status, couldReachServer })}</AlertTitle>

        <Typography variant="body2" gutterBottom>
          {msg}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {getAlertMsg({ status, couldReachServer, env })}
        </Typography>

        {renderButton()}
      </Alert>

      <SetApiKeysFormDialog
        open={mustShowSetKeysFormDialog}
        onClose={handleOnSetKeysFormDialogClose}
        onSave={handleOnSetKeysFormDialogSave}
        autoFocus={env}
      />
    </Box>

  );
};

RequestErrorAlert.propTypes = {
  error: CustomPropTypes.error.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default RequestErrorAlert;
