/* eslint-disable no-plusplus */
import BaseService from './BaseService';
import dayjs from '../custom/customDayjs';

const csvToJson = (string, headers, quoteChar = '"', delimiter = ',') => {
  const regex = new RegExp(
    `\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`,
    'gs',
  );
  const match = (str) => [...str.matchAll(regex)]
    .map((m) => m[2])
    .filter((_, i, a) => i < a.length - 1); // cut off blank match at end

  const lines = string.split('\n');
  const heads = headers || match(lines.splice(0, 1)[0]);

  return lines.map((line) => match(line).reduce(
    (acc, cur, i) => ({
      ...acc,
      [heads[i] || `extra_${i}`]: cur.length > 0 ? Number(cur) || cur : null,
    }),
    {},
  ));
};

export default {
  async fetchBetweenDates({ startDate, endDate }) {
    const CSV = await BaseService.request({
      url: `parkingBookings/report/${startDate}/${endDate}`,
    });
    return csvToJson(CSV).map((val) => ({
      ...val,
      startDate: dayjs(
        `${val['start date']} ${val['start time'] || ''}`,
        'DD/MM/YY h:mm A',
      ),
      endDate: val['end date'] && val['end time'] ? dayjs(
        `${val['end date']} ${val['end time'] || ''}`,
        'DD/MM/YY h:mm A',
      ) : undefined,
    }));
  },
};
