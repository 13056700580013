/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import dayjs from '../../../custom/customDayjs';

const useStyles = makeStyles((theme) => ({
  tableIconButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > *': {
      width: '150px',
    },
  },
}));

const CustomToolbarSelect = ({ onDateChange, start, end }) => {
  const classes = useStyles();

  const handleDateChange = (field) => (date) => {
    if (field === 'start') return onDateChange?.(dayjs(date), end);
    if (field === 'end') return onDateChange?.(start, dayjs(date));
    return null;
  };

  return (
    <Box className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <KeyboardDatePicker
          margin="small"
          id="date-start"
          label="Date (début)"
          format="dd/MM/yyyy"
          value={start}
          onChange={handleDateChange('start')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <KeyboardDatePicker
          margin="dense"
          id="date-end"
          label="Date (fin)"
          format="dd/MM/yyyy"
          value={end}
          onChange={handleDateChange('end')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

CustomToolbarSelect.propTypes = {
  onDateChange: PropTypes.func.isRequired,
};

export default CustomToolbarSelect;
