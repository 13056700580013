import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import MUIDataTable from 'mui-datatables';

import RequestErrorAlert from '../RequestErrorAlert';
import CustomToolbar from './CustomToolbar';
import CouponsActions from '../../../actions/CouponsActions';

import {
  getCampaignsTableColumns,
} from '../../../utils/coupons';
import dayjs from '../../../custom/customDayjs';

const today = dayjs().add(1, 'day');

const CouponsCampaignsTable = () => {
  const sessions = useSelector((state) => state.sessions?.sessions || []);
  const [waiting, setWaiting] = useState(false);
  const [fetchCampaignsError, setFetchCampaignsError] = useState(null);

  const [startDate, setStartDate] = useState(today.subtract(30, 'days'));
  const [endDate, setEndDate] = useState(today);

  const onFetchAllCampaignsSuccess = () => {
    setWaiting(false);
  };

  const onFetchAllCampaignsError = (error) => {
    setWaiting(false);
    setFetchCampaignsError(error);
  };

  const fetchAllCampaigns = (start, end) => {
    setWaiting(true);
    setFetchCampaignsError(null);
    CouponsActions.fetchBetweenDates({
      startDate: (start || startDate).format('YYYY-MM-DD'),
      endDate: (end || endDate).format('YYYY-MM-DD'),
      onSuccess: onFetchAllCampaignsSuccess,
      onError: onFetchAllCampaignsError,
    });
  };

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    fetchAllCampaigns(start, end);
  };

  const renderContent = () => {
    if (waiting) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    if (fetchCampaignsError) {
      return (
        <RequestErrorAlert
          error={fetchCampaignsError}
          onRetry={fetchAllCampaigns}
        />
      );
    }

    return (
      <MUIDataTable
        columns={getCampaignsTableColumns()}
        data={sessions}
        title="Sessions de stationnement"
        options={{
          download: true,
          print: true,
          responsive: 'simple',
          selectableRows: 'none',
          customToolbar: () => (
            <CustomToolbar start={startDate} end={endDate} onDateChange={handleDateChange} />
          ),
        }}
      />
    );
  };

  return <Box>{renderContent()}</Box>;
};

export default CouponsCampaignsTable;
