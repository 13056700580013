import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'fontsource-roboto';
import './index.css';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';

import CouponsControlPanel from './CouponsControlPanel';

import store from './store';

import genList from './genPasswords';

genList();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[800],
    },
    secondary: {
      main: red.A200,
    },
    error: {
      main: red[700],
    },
    warning: {
      main: orange[800],
    },
    info: {
      main: lightBlue[600],
    },
    success: {
      main: green[400],
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CouponsControlPanel />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
