import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../logo.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.grey['800'],
  },
}));

const TopAppBar = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar variant="dense">
        <Box mr={4} ml={2}>
          <img src={logo} alt="Seety" style={{ maxHeight: 20 }} />
        </Box>

        <Typography variant="h6" color="inherit">
          BackOffice paiement
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
