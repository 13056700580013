import store from '../store';

export default {
  addToQueue({
    severity, msg, duration = 3000, canBeDumped = false, showClose = false,
  }) {
    store.dispatch({
      type: 'ADD_SNACKBAR_TO_QUEUE',
      snackbar: {
        severity,
        msg,
        duration,
        canBeDumped,
        showClose,
      },
    });
  },

  addWaitingForServerToQueue() {
    store.dispatch({
      type: 'ADD_SNACKBAR_TO_QUEUE',
      snackbar: {
        severity: 'info',
        msg: 'Waiting for server...',
        duration: null,
        canBeDumped: true,
        showClose: false,
      },
    });
  },

  addRequestErrorToQueue({ error }) {
    store.dispatch({
      type: 'ADD_SNACKBAR_TO_QUEUE',
      snackbar: {
        severity: 'error',
        msg: error.backendMsg || error.msg,
        duration: null,
        canBeDumped: false,
        showClose: true,
      },
    });
  },

  addSuccessToQueue({ msg }) {
    store.dispatch({
      type: 'ADD_SNACKBAR_TO_QUEUE',
      snackbar: {
        severity: 'success',
        msg,
        duration: 3000,
        canBeDumped: false,
        showClose: false,
      },
    });
  },

  emptyQueue() {
    store.dispatch({
      type: 'EMPTY_SNACKBARS_QUEUE',
    });
  },

  updateHeadOfQueue({ duration }) {
    store.dispatch({
      type: 'UPDATE_HEAD_OF_QUEUE',
      snackbar: {
        duration,
      },
    });
  },

  removeHeadOfQueue() {
    store.dispatch({
      type: 'REMOVE_HEAD_OF_QUEUE',
    });
  },
};
