import produce from 'immer';

export default function coupons(state = {
  sessions: [],
}, action) {
  switch (action.type) {
    case 'FETCH': {
      return produce(state, (draft) => {
        draft.sessions = action.sessions;
      });
    }
    default:
      return state;
  }
}
