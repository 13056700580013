import sha256 from 'crypto-js/sha256';

function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function genKey(login) {
  const password = randomString(10);
  const key = sha256(
    `${login}`.toLowerCase().trim() + `${password}`.trim(),
  ).toString();

  console.log(`${login}, ${password}, ${key}`);
  return { login, password, key };
}

const emails = [
  'parking9@rca.charleroi.be',
  'daniel.geurinckx@rca.charleroi.be',
];

function genList() {
  emails.map(genKey);
}

export default genList;
