import PropTypes from 'prop-types';

export default {
  error: PropTypes.shape({
    status: PropTypes.number,
    msg: PropTypes.string.isRequired,
    couldReachServer: PropTypes.bool.isRequired,
    env: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    backendMsg: PropTypes.string,
  }),

  campaign: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    validAfter: PropTypes.string.isRequired,
    validBefore: PropTypes.string.isRequired,
    validPartners: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired,
      fr: PropTypes.string.isRequired,
      nl: PropTypes.string.isRequired,
    }).isRequired,
    forFirstPayment: PropTypes.bool.isRequired,
    canBeAddedManually: PropTypes.bool.isRequired,
    canOnlyBeUsedOnceByPlate: PropTypes.bool.isRequired,
  }),
};
